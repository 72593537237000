import React from 'react'
import { UnderConstruction } from '../../../components'

const GeneralMaintenance = () => (
  <UnderConstruction />
  // <Layout headerBreakpoint={170}>
  //   <SEO title="General Maintenance" />

  //   <Spotlight img={data.roller}/>

  //   <section>
  //     <h1>General Maintenance</h1>
  //   </section>

  //   <section>
  //     <div>
  //       <div style={{overflow: 'hidden', margin: '-20px'}}>
  //         <Card image={data.glass}>
  //           <h3>New Builds</h3>
            
  //           <p></p>

  //           <Link to="/services/general-maintenance/new-builds" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Waterproofing</h3>
            
  //           <p></p>

  //           <Link to="/services/general-maintenance/waterproofing" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Boarding Up</h3>
            
  //           <p></p>

  //           <Link to="/services/general-maintenance/boarding-up" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Interior Maintenance</h3>
            
  //           <p></p>

  //           <Link to="/services/general-maintenance/waterproofing" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Draught Proofing</h3>
            
  //           <p></p>

  //           <Link to="/services/general-maintenace/draught-proofing" className="button primary">Find Out More</Link>
  //         </Card>
  //       </div>
  //     </div>
  //   </section>
  // </Layout>
)

export default GeneralMaintenance
